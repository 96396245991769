<template>
  <v-container fluid>
    <v-card style="overflow: hidden">
      <v-card-title class="accent text-h5 font-weight-bold pa-3 mb-3">
        <v-icon color="primary" left>mdi-presentation</v-icon>
        Palestras
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          @click="ordenar = !ordenar"
          :disabled="ordenar"
          class="white--text mr-2"
        >
          <v-icon left> mdi-order-numeric-ascending</v-icon>
          Ordenar
        </v-btn>
        <v-btn
          :to="{ path: 'palestras/adicionar' }"
          color="xbColor"
          class="white--text"
        >
          <v-icon left> mdi-plus</v-icon>
          Adicionar
        </v-btn>
      </v-card-title>
      <v-tabs @change="tabChanged" class="" color="xbColor" v-model="tab">
        <v-tab class="font-weight-bold align-center">
          01/03<br />
          Sexta
        </v-tab>
        <v-tab class="font-weight-bold">
          02/03<br />
          Sábado
        </v-tab>
        <v-tab class="font-weight-bold">
          03/03<br />
          Domingo
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <PalestrasData
            v-if="tab === 0 && !ordenar"
            diaPalestra="2024-03-01"
          />
          <PalestrasDraggable
            v-if="tab === 0 && ordenar"
            diaPalestra="2024-03-01"
            :ordenar.sync="ordenar"
          />
        </v-tab-item>
        <v-tab-item>
          <PalestrasData
            v-if="tab === 1 && !ordenar"
            diaPalestra="2024-03-02"
          />
          <PalestrasDraggable
            v-if="tab === 1 && ordenar"
            diaPalestra="2024-03-02"
            :ordenar.sync="ordenar"
          />
        </v-tab-item>
        <v-tab-item>
          <PalestrasData
            v-if="tab === 2 && !ordenar"
            diaPalestra="2024-03-03"
          />
          <PalestrasDraggable
            v-if="tab === 2 && ordenar"
            diaPalestra="2024-03-03"
            :ordenar.sync="ordenar"
          />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "Palestras",

  data() {
    return {
      search: "",
      tab: 0,
      ordenar: false,
    };
  },

  watch: {},

  components: {
    PalestrasData: () => import("./Palestras.vue"),
    PalestrasDraggable: () => import("./PalestrasDraggable.vue"),
  },

  computed: {},

  methods: {
    tabChanged() {
      this.ordenar = false;
    },
  },

  mounted() {},
};
</script>

<style lang="scss" scoped></style>
